export default {
  methods: {
    /////////////////////////////////////
    //////////////
    ////////説明
    ////////ログイン状態を確認
    ///
    ///引数：なし
    ///////////////////////
    ////////////////////////////////////////////////////
    authStateCheck(store,router) {
      console.log("authStackCheckがよばれまsチア");
      var sessionLen = sessionStorage.length;
      var sessionKey;
      if (sessionLen > 0) {
        for (var i = 0; i < sessionLen; i++) {
          sessionKey = sessionStorage.key(i);
          console.log(sessionKey);
          console.log("特定文字列があるか");
          console.log(sessionKey.indexOf("firebase:authUser"));
          if (sessionKey.indexOf("firebase:authUser") != -1) {
            console.log("セッションがありました");
            sessionKey = true;
            console.log(sessionKey);
            break;
          } else {
            console.log("セッションがありませんでした");
            sessionKey = false;
          }
        }
      } else {
        sessionKey = false;
      }
      if (sessionKey == false) {
        store.commit("resetUser");
        router.push({ path: "/" });
      }
    },
  }
}